import React, { useEffect, useRef } from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import "../../Styles/Homeproductsection.css"
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { SaveVisitedProduct } from "../GenericCode/GenericCode";

const HomeProductSection = (props) => { /** will remove this component when data comes */
  const navigate = useNavigate();
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2
        }
      };

      const handleImgaeClick = (item) => {
        debugger
        SaveVisitedProduct(item);
        navigate(`/productdetails/${item?.id}`, { state: { item } });
      }

      const handleAllProducts = () => {
        navigate("/products");
      }

  return (
    // <div className="homeproductsection-main">
    <Carousel responsive={responsive}
        // autoPlay={true}
        autoPlaySpeed={1500}
    >
      {props?.data?.map((items, index) => (
        <div className="card">
        <img className="product--image" loading="lazy" src={items?.imageUrl} alt={items?.description.length > 70 ? items?.description.slice(0, 70) + "..." : items?.description} onClick={() => handleImgaeClick(items)}/>
        <h3>{items?.name}</h3>
        <p className="description">{items?.type}</p>
        <p className="price">&euro;{Number(items?.price)}</p>
        {props?.from !== "RecentlyViewed" && (
        <p>
          <Button
              className="View-Product-Button"
              color="default"
              // variant="filled"
              onClick={handleAllProducts}
              aria-hidden="true"
              tabindex="-1"
            >
              More Like this
            </Button>
        </p>)}
      </div>
      ))}
    {/* <div className="card">
      <img className="product--image" src={Homeproductimage_1} alt="product image" />
      <h4>Zapara</h4>
      <p className="description">Wedding Suit</p>
      <p className="price">$1200</p>
      <p>
        <Button
            className="View-Product-Button"
            color="default"
            // variant="filled"
          >
            More Like this
          </Button>
      </p>
    </div>
    <div className="card">
      <img className="product--image" src={Homeproductimage_2} alt="product image" />
      <h4>Harper</h4>
      <p className="description">Long Sleeves T-Shirt</p>
      <p className="price">$130</p>
      <p>
        <Button
            className="View-Product-Button"
            color="default"
            // variant="filled"
          >
            More Like this
          </Button>
      </p>
    </div>
    <div className="card">
      <img className="product--image" src={Homeproductimage_3} alt="product image" />
      <h4>Zara</h4>
      <p className="description">Urban Style Hoodeis</p>
      <p className="price">$250</p>
      <p>
        <Button
            className="View-Product-Button"
            color="default"
            // variant="filled"
          >
            More Like this
          </Button>
      </p>
    </div>
    <div className="card">
      <img className="product--image" src={Homeproductimage_4} alt="product image" />
      <h4>H&M</h4>
      <p className="description">Printed Dress for Summer</p>
      <p className="price">$120</p>
      <p>
        <Button
            className="View-Product-Button"
            color="default"
            // variant="filled"
          >
            More Like this
          </Button>
      </p>
    </div>
    <div className="card">
      <img className="product--image" src={Homeproductimage_5} alt="product image" />
      <h4>Leopard Printed Shoes For Women</h4>
      <p className="price">$200</p>
      <p>sdf</p>
      <p>
        <Button
            className="View-Product-Button"
            color="default"
            // variant="filled"
          >
            More Like this
          </Button>
      </p>
    </div>
    <div className="card">
      <img className="product--image" src={Homeproductimage_6} alt="product image" />
      <h4>Converse</h4>
      <p className="price">Black Converse Shoes</p>
      <p>$150</p>
      <p>
      <Button
            className="View-Product-Button"
            color="default"
            // variant="filled"
          >
            More Like this
          </Button>
      </p>
    </div> */}
</Carousel>
// </div>
     /* <div className="homeproductsection-main">
      <Carousel
    //     ref={carouselRef}
    //     // autoplay
    //     autoplaySpeed={1500}
    //     dots={false}
    //     arrows={false}
    //     slidesToShow={4}
    //     slidesToScroll={1}
    //     style={{ height: "800px" }}
    //   >
    //     <div style={contentStyle}>
    //       <img
    //         src={carousel_image1}
    //         alt="carousel 1"
    //         style={{
    //           height: "500px",
    //           width: "100%",
    //           objectFit: "cover",
    //           borderRadius: "4px",
    //           border: "15px solid #fff",
    //         }}
    //       />
    //       <h3>Product 1</h3>
    //       <p>Product Description</p>
    //       <p>Product Description</p>
    //       <p>Product Description</p>
    //       <p>Product Description</p>
    //       <p>Product Description</p>
    //     </div>
    //     <div style={contentStyle}>
    //       <img
    //         src={carousel_image2}
    //         alt="carousel 2"
    //         style={{
    //           height: "500px",
    //           width: "100%",
    //           objectFit: "cover",
    //           borderRadius: "4px",
    //           border: "15px solid #fff",
    //         }}
    //       />
    //     </div>
    //     <div style={contentStyle}>
    //       <img
    //         src={carousel_image3}
    //         alt="carousel 3"
    //         style={{
    //           height: "500px",
    //           width: "100%",
    //           objectFit: "cover",
    //           borderRadius: "4px",
    //           border: "15px solid #fff",
    //         }}
    //       />
    //     </div>
    //     <div style={contentStyle}>
    //       <img
    //         src={carousel_image4}
    //         alt="carousel 4"
    //         style={{
    //           height: "500px",
    //           width: "100%",
    //           objectFit: "cover",
    //           borderRadius: "4px",
    //           border: "15px solid #fff",
    //         }}
    //       />
    //     </div>
    //     <div style={contentStyle}>
    //       <img
    //         src={carousel_image5}
    //         alt="carousel 5"
    //         style={{
    //           height: "500px",
    //           width: "100%",
    //           objectFit: "cover",
    //           borderRadius: "4px",
    //           border: "15px solid #fff",
    //         }}
    //       />
    //     </div>
    //   </Carousel>
    // </div> */
  );
};

export default HomeProductSection;
